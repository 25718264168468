define("discourse/plugins/discourse-category-home/discourse/initializers/category-home-edits", ["exports", "discourse/lib/utilities", "discourse/lib/ajax", "discourse/lib/url", "discourse/lib/plugin-api", "@ember/object", "discourse-common/lib/get-url"], function (_exports, _utilities, _ajax, _url, _pluginApi, _object, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "category-home-edits",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.discourse_category_home_enabled) {
        (0, _ajax.ajax)("/categoryhome/home_category", {
          type: "GET"
        }).then(function (result) {
          if (Number.isInteger(result)) {
            let url = `/c/${result}`;
            (0, _utilities.setDefaultHomepage)(url);
            _url.default.routeTo(url);
          }
        }).catch(function (err) {
          console.log({
            err
          });
        }).finally(function () {
          // placeholder
        });
        (0, _pluginApi.withPluginApi)("0.8.32", api => {
          api.modifyClass("component:category-drop", {
            allCategoriesUrl: (0, _object.computed)("parentCategoryUrl", "selectKit.options.subCategory", function () {
              return (0, _getUrl.default)(this.selectKit.options.subCategory ? `${this.parentCategoryUrl}/all` || "/" : "/latest");
            })
          });
        });
      }
    }
  };
});